<template lang="pug">
    .lti-wrap
        ui-title.title(v-html="locales.title", variant="subtitle", component="p")
        ul.bullets
            li.bullet(
                v-for="bullet in locales.bullets",
                :key="bullet"
            )
                article-icon.icon(src="hex")
                ui-paragraph.text(v-html="bullet")
</template>

<script>
import UiTitle from '../../../../ui/Title.vue';
import UiParagraph from '../../../../ui/Paragraph.vue';
import ArticleIcon from '../../../Icon.vue';

export default {
    name: 'lti',
    components: {
        UiParagraph,
        UiTitle,
        ArticleIcon,
    },
    computed: {
        /* eslint-disable max-len */
        locales() {
            return {
                title: 'Один из&nbsp;инструментов, способствующих реализации глобальной стратегии борьбы&nbsp;с туберкулезом,&nbsp;&mdash; <em>выявление пациентов с&nbsp;латентной туберкулезной инфекцией <nobr>(ЛТИ):</nobr></em>',
                bullets: [
                    'выявление групп риска по&nbsp;ЛТИ и&nbsp;определение их&nbsp;приоритетности',
                    'диагностика и&nbsp;лечение ЛТИ',
                    'рекомендации по&nbsp;методам диагностики и&nbsp;подходам к&nbsp;лечению с&nbsp;надлежащим учетом этических требований',
                ],
            };
        },
        /* eslint-enable max-len */
    },
};
</script>

<style lang="scss" scoped>
.lti-wrap {
    width: 100%;
    max-width: 750px;
    margin: rem(96) auto;

    .title {
        margin: rem(32) 0;
    }

    .bullets {
        width: 100%;
        padding: rem(34) 0;
        display: block;
        position: relative;
        list-style-type: none;

        .bullet {
            width: 100%;
            margin-bottom: rem(24);
            padding-left: rem(24);
            position: relative;

            .icon {
                width: rem(18);
                height: rem(16);
                position: absolute;
                top: 0;
                left: 0;
            }

            &:nth-last-child(1) {
                margin-bottom: 0;
            }
        }

        &::before,
        &::after {
            content: '';
            width: 100%;
            height: rem(210);
            position: absolute;
            top: 0;
            z-index: 0;
            background: url('../../../../../assets/images/icons/decoration/product-block.svg');
            background-size: auto 100%;
            background-position: right top;
        }

        &::before {
            right: 110%;
        }

        &::after {
            left: 110%;
            transform: scaleX(-1);
        }
    }

    @media (--viewport-tablet) {
        max-width: unset;
        margin: rem-mobile(64) auto;
        padding: 0 var(--offset-x);

        .title {
            margin: rem-mobile(32) 0;
        }

        .bullets {
            padding: rem-mobile(34) 0;

            .bullet {
                margin-bottom: rem-mobile(24);
                padding-left: rem-mobile(24);

                .icon {
                    width: rem-mobile(18);
                    height: rem-mobile(16);
                }
            }

            &::before,
            &::after {
                content: none;
                display: none;
            }
        }
    }
}
</style>
